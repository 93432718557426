@import "styles/shared";

.User-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  width: 100%;
  min-height: calc(100vh - #{$m_xxlarge});
  padding: $m_large $m_large $m_xxlarge;
  background-color: $c_byggforetagen_fasttrack_menu;

  @media all and (min-width: $beta_query) {
    min-height: 600px;
    height: auto;
    @include theme-box-shadow(c_shadow, false, 0px, 7px, 6px);
  }
  &__expanded {
    @include gutters;
    padding: $m_default 0;
  }
  &__list {
    margin-bottom: $m_gutter_xlarge;
    &__item {
      border-bottom: 1px solid transparent;
      @include theme-border-color(c_body-bg);
      &-btn {
        position: relative;
        @include theme-font-size(s_medium_large);
        @include theme-font-weight(fw_medium);
        @include theme-color(c_body-text);
        padding: $m_medium_large;
        display: flex;
        width: 100%;
      }
      &--inactive {
        opacity: 0.4;
      }
    }
    &__icon {
      margin-right: $m_default;
      margin-top: 2px;
    }
    &__toggle-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: $m_gutter_default;
      line-height: 1;
      min-width: 14px;
      vertical-align: middle;
      transition: transform 0.2s ease;
      svg {
        max-width: 24px;
        width: 100%;
        height: auto;
        display: inline-block;
      }
      @media all and (min-width: $delta_query) {
        width: 24px;
      }
      &--toggled {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
  }
  &__confirm {
    padding-bottom: $m_medium_large;
    &__button-row {
      display: flex;
      margin-top: $m_medium_large;
    }
    &__cancel-button {
      @include button($blocked: true, $type: "secondary", $extraHeight: true);
      margin-right: $m_medium_large;
    }
    &__confirm-button {
      @include button($blocked: true, $type: "inverse", $extraHeight: true);
      @include theme-background-color(c_body-bg);
    }
  }
  &__item {
    width: 50%;
    margin-bottom: $m_small;
  }

  &__language {
    padding: $m_medium_large;
    @include theme-color(c_body-text);
    @include theme-font-weight(fw_bold);
    &--selected {
      @include theme-background-color(c_body-bg);
      border-radius: $ra_xlarge;
    }
  }
  &__support {
    padding-bottom: $m_medium_large;
    &__link {
      @include link;
    }
  }
  &__logout {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__logout-btn {
    @include button($blocked: true, $type: "inverse");
    @include theme-background-color(c_body-bg);
    position: relative;
    margin-bottom: $m_xlarge;
    height: 60px;
    &-icon {
      @include buttonIcon($type: "inverse");
    }
    &:disabled {
      @include theme-background-color(c_body-bg);
      @include theme-color(c_action-primary);
      opacity: 0.4;
      cursor: default;
    }
  }
}
